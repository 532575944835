import request from '../utils/request'
import baseURL from '../utils/config'

//下拉列表
export function getclasslist() {
    return request({
        url: baseURL.baseURL + 'api/Adm_GoodsType/GetList',
        method: 'get',
    })
}
//新增
export function AddOrEdit(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_GoodsType/AddOrEdit',
      method: 'post',
      data
  })
}
//删除
export function Delete(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_GoodsType/Delete',
      method: 'get',
      params:data
  })
}
//删除
export function SetDefault(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_GoodsType/SetDefault',
      method: 'get',
      params:data
  })
}
//排序
export function SortChange(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_GoodsType/SortChange',
      method: 'post',
      data
  })
}
